import React from "react";

function Post(props) {

  const { rName } = props;

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <p>Posts! {url}</p>
  )
}
export default Post
